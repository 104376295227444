.module_mod_iwp_cart {

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &.highlight {

    $radius: $borderRadius;
    $padding: 24px;

    .cart {
      background-color: lighten($mainColor, 10%);
      padding: $padding;
      border-radius: $radius;
      color: white;

      .main-color, .quantity {
        color: white;
      }

      .info, .remove a {
        color: transparentize(white, 0.4);
      }

      //.bottom {

      .continue {
        max-width: 100%;
        flex-basis: 100%;
        background-color: $mainColor;
        border: none;
        margin-top: 8px;
        margin-bottom: -$padding;
        padding: 0;
      }

      .btn {
        background: none;
        border: none;
        text-align: center;
        padding: 16px;
      }

      .total {
        color: white;
        max-width: 100%;
        flex-basis: 100%;

        span {
          display: inline-block;
        }
      }
      //}
    }
  }

  .animate {
    animation: bounceIn 1s;
  }

  .item {
    $fontSize: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-basis: 100%;

    & + .item {
      margin-top: 1rem;
    }

    .info {
      display: flex;
      max-width: 85%;

      .name {
        font-size: .9em;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 2em);
      }
    }

    .quantity {
      width: 2em;
      flex-basis: 2em;
      font-size: $fontSize;
      white-space: nowrap;
      flex-shrink: 0;
    }

    .title {
      font-size: $fontSize;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      display: block;

      @media (min-width: $screen-md) {
        white-space: nowrap;
      }
    }

    .remove {
      //width: 24px;

      a {
        color: $textColor;
        opacity: 0.6;
        font-size: 20px;
        transition: opacity 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .total {
    margin-bottom: 6px;

    @media (min-width: $screen-lg) {
      span {
        display: block;
      }
    }
  }

  .bottom {
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px transparentize(#ccc, 0.6) solid;
  }

  .cartEmpty {
    .icon {
      color: inherit;
      opacity: 0.3;
      font-size: 60px;
    }
  }

  .dropdown {

    .dropdown-menu {
      border: none;
      min-width: 300px;
    }

    > .icon {
      position: relative;
      display: block;
      //font-size: 16px;
      //color: $textColor;
      //width: 1em;
      //margin-top: 20px;


      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $danger;
        border: 2px #fff solid;
        color: #FFFFFF;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-50%,-50%);
        font-size: 50%;
        font-weight: bold;
      }
    }
  }
}