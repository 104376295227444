html {
    height: 100%;
    scroll-behavior: smooth;

    &.hidden {
        display: inherit !important;
    }

    body {
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;

        &.modal-open {
            -webkit-overflow-scrolling: touch;
        }
    }
}

a {
    &:hover {
        color: inherit;
    }
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit !important;
}

.intro-text {
    font-size: 13px;
}

.owl-carousel {

    &.preserve-img {

        .owl-item {

            img {
                width: auto;
                margin: auto;
                max-width: 100%;
            }
        }
    }

}

.pagination {

    > li {

        & + li {
        	margin-left: .5em;
        	border: none;
        }

        > .page-link {
            $size: 32px;

            border: none;
            color: $gray-600;
            background-color: transparent;
            text-align: center;
            height: $size;
            width: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border-radius: 100%;

            &:hover {
                color: $body-color;
            }
        }

        &.disabled {
            > span {
                border: none;
            }

            > .page-link {
                background-color: transparent;
                color: $gray-400;
            }
        }

        &.active {

            > .page-link, > .page-link:hover, > span, > span:hover {
                background-color: white;
                color: lighten($mainColor, 10%);
                border: 1px $gray-300 solid;
            }
        }
    }
}

.breadcrumb {
    display: block !important;
    background: none;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0;
    font-size: 90%;

    li {
        display: inline-block;
        a {
            //color: #888;
        }

        & + li:before {
            //color: #eee;
        }

        &.active {
            //color: #aaa;
        }
    }
}

.badge {

    &.badge-lg {
        padding: $badge-lg-padding-y $badge-lg-padding-x;
        font-size: $badge-lg-font-size;
        font-weight: $badge-lg-font-weight;

        &.badge-pill {
            padding: 0 $badge-pill-lg-padding-x;
        }
    }

    &.badge-success {
        background-color: #28a745;
        color: $white;
    }

    &.badge-danger {
      background-color: #dc3545;
      color: $white;
    }

    &.badge-warning {
        background-color: #ed8e00;
        color: $white;
    }

    &.badge-light {
        background-color: #e1e1e1 !important;
        color: $body-color;
    }
}

.font-weight-bold {
    font-weight: 600 !important;
}


.progress {
    overflow: hidden;
    position: relative;

    &.indeterminate div:first-child {
        content: '';
        position: absolute;
        height: 100%;
        animation: indeterminate_first 1.5s infinite ease-out;
    }

    &.indeterminate div:last-child {
        content: '';
        position: absolute;
        height: 100%;
        animation: indeterminate_second 1.5s infinite ease-in;
    }

    @keyframes indeterminate_first {
        0% {
            left: -100%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }

    @keyframes indeterminate_second {
        0% {
            left: -150%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
}