@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);

@import "variables";
//@import "~bootstrap/scss/bootstrap";
//@import "../node_modules/bootstrap/scss/bootstrap";
@import "../../../../media/vendor/bootstrap/scss/bootstrap";

@import "forms/input-group";

@import "macros";
@import "accordion";
@import "commons";
@import "dropdown";
@import "menu";
@import "forms";
@import "buttons";
@import "modules";
@import "components";
@import "layout";
@import "login";
@import "carousel";
@import "megamenu";

// components
@import "components/iwp_awards";
@import "components/iwp_contacts";
@import "components/iwp_voucher";
@import "components/iwp_users";
@import "components/iwp_commercialagreements";


// modules
@import "modules/login";
//@import "modules/shopcat";
@import "modules/iwp_categories";
//@import "modules/cart";
@import "modules/iwp_cart";
@import "modules/rangebox";
//@import "modules/filters";
@import "modules/iwp_filters";
//@import "modules/orderstatus";
@import "modules/iwp_orderstatus";
//@import "modules/objectives";
@import "modules/iwp_targets";
//@import "modules/remind_commercial_agreement";
@import "modules/iwp_support";
//@import "modules/welcome";
@import "modules/iwp_welcome";
@import "modules/iwp_brands";
//@import "modules/points";
@import "modules/iwp_points";
@import "modules/articles_category";

@import "plugins/iwp_remindcommercialagreements";
