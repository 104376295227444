.module_mod_iwp_filters {

  > div {

    .title {
      margin-bottom: 15px;
      color: #aaa;
    }

    @include media-breakpoint-down(md) {
      .buttons {
        margin-top: 20px;

        li, .btn {
          width: 100%;
        }

        li {
          margin: 10px 0;
        }

        .btn {
          white-space: normal;
        }
      }
    }

  }

  > .tab {
    border: 1px $card-border-color solid;
    padding: $grid-gutter-width/2;
    background-color: white;


    @media (max-width: $screen-sm) {
      #filter-search-form {
        > .inner {
          position: fixed;
          left: 100vw;
          background-color: white;
          transition: all .3s $transitionEasing;
          z-index: 9000;
          padding: 20px;
          top: 0;
          height: 100%;
          width: 100%;
          overflow: auto;

          &.open {
            left: 0;
          }


          .toggle {
            padding: 20px;
            position: relative;
            right: -20px;
            top: -10px;
            font-size: 32px;
            opacity: .5;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .title {
      margin-bottom: 15px;
      color: #aaa;
    }

    .filters {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;
      flex-wrap: wrap;
    }

    .form-group {
      flex-grow: 1;
      padding: 15px;
      flex-basis: 25%;
      //max-width: 25%;
      min-width: 200px;

      @media (max-width: $screen-md) {
        min-width: 50%;
        flex-basis: 50%;
      }

      @media (max-width: $screen-sm) {
        min-width: 100%;
        flex-basis: 100%;
        padding-bottom: 0;
      }

      .bootstrap-select {
        width: 100%;
      }

    }

    button[type="reset"] {
      color: #777;
    }

    > .text {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(md) {
      .buttons {
        margin-top: 20px;

        li, .btn {
          width: 100%;
        }

        li {
          margin: 10px 0;
        }

        .btn {
          white-space: normal;
        }
      }
    }

  }

  &.pos_left, &.pos_right {

    .form-group {
      margin-bottom: 0;
    }

    .buttons {
      margin-top: 30px;

      li {
        display: block;
        margin: 10px 0;
      }

      .btn {
        display: block;
        width: 100%;
        white-space: normal;
      }
    }
  }

  #filter-search-form {
    .filters {
      .search-input-group {
         border-bottom: $border-width * 2 solid $black;

        > .input-group-text, > .form-control {
          border: none;
        }
      }
    }
  }

}