.com_iwp_contacts {
	#content {
		padding: 0;
	}

	&.view-ticket {

		&.layout-invoice {
			#main-content {
				padding-top: 0;
				padding-bottom: 0;
			}

			.svg-icon {
				width: 70%;

				&.svg-icon-send {
					width: 60%;

					path,
					circle {
						fill: white;
					}
				}
			}

			.chat {
				$self: &;
				height: 100%;
				//width: 100%;

				.chat-container {
					height: 100%;
					width: 100%;

					.chat-wrapper {
						background-color: var(--bg-page);
						height: 100%;
						width: 100%;
						border-left: 1px solid var(--border-color);
						border-right: 1px solid var(--border-color);
						overflow: hidden;
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.chat-header {
							width: 100%;
							border-bottom: 1px solid $border-color;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: center;

							.chat-infos {
								flex-grow: 1;

								.chat-infos-wrapper {
									width: 100%;
									height: 100%;
									//display: flex;
									//justify-content: flex-start;
									//align-items: center;

									.chat-infos-details {
										//margin-left: 0.8rem;
										display: flex;
										justify-content: space-between;
										align-items: center;
										flex-direction: row;

										.chat-infos-name {
											font-weight: bold;
											white-space: nowrap;
											word-break: break-all;
											text-overflow: ellipsis;
											overflow: hidden;
											font-size: .9rem;
										}

									}
								}
							}
						}

						.chat-content {
							flex-grow: 1;
							overflow-y: auto;

							.chat-list-messages {
								display: flex;
								flex-direction: column;
								justify-content: flex-start;

								.chat-date {
									font-size: 0.8rem;
									margin-top: 0.5rem;
									margin-bottom: 0.5rem;
									color: #9b9b9b;
									align-self: center;
									position: sticky;
									top: 0;
									z-index: 100;
									background: white;
									padding: 4px 8px;
									border-radius: 50px;
								}

								& > div {
									display: flex;
									flex-direction: column;
									justify-content: flex-start;
									align-items: flex-start;

									> .chat-bubble {
										position: relative;
										color: var(--colour-text-darkest);
										padding: 0.75rem 1rem;
										margin-bottom: 0.2rem;
										border-radius: 22px;
										background-color: var(--bg-page);
										max-width: 30rem;
										font-size: 0.9rem;
										overflow: hidden;
										overflow-wrap: break-word;
										word-break: break-word;

										&.chat-bubble-me {
											margin-left: 2rem;
											background-color: $light;
											align-self: flex-end;
										}

										&.chat-bubble-you {
											margin-right: 2rem;
											color: white;
											background-color: $primary;
										}


										&.chat-bubble-attachment {
											#embed {
												overflow: hidden;
												max-height: 250px;
												height: 250px;
											}

											.chat-message-info {
												margin-top: 0px;
												float: none;
												margin-left: auto;
											}
										}

										.chat-message-info {
											display: flex;
											align-items: end;
											justify-content: end;
											float: right;
											width: 75px;
											margin-top: 10px;
										}
									}

									&.you + .you,
									&.me + .me {
										margin-top: 0.2rem;
									}

									& + div {
										margin-top: 0.7rem;
									}
								}
							}
						}

						.chat-send-container {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.custom-form-send-wrapper {
								width: 100%;
								height: 100%;
								position: relative;

								.custom-form {
									color: #363636;
									padding: 1.5rem;
									border-radius: 13px;
								}

								#input-container,
								#input-container-attachment {
									//padding-right: 6rem;
									padding-right: 1.25rem;
									padding-left: 1.25rem;
									background-color: #f2f2f2;
									border: none;
									display: flex;
									align-items: center;
									justify-content: center;

									.input-text-group {
										position: relative;
										width: calc(100% - 5.6rem);

										#input-text,
										#input-text-attachment {
											overflow-x: hidden;
											overflow-y: auto;
											white-space: pre-wrap;
											word-wrap: break-word;
											z-index: 1;
											max-height: 100px;
											min-height: 20px;
											padding: 0 0 0 2px;
											outline: 0;
											transition: 0.2s padding ease-in-out;

											&:empty + .input-placeholder {
												opacity: 1;
											}

											&:focus {
												//padding: 0 6px 0px;
											}

											&:focus + .input-placeholder {
												//padding: 0 6px 0px;
											}
										}

										.input-placeholder {
											color: #a0a0a0;
											top: 0;
											padding: 0 5px 0;
											pointer-events: none;
											user-select: none;
											position: absolute;
											opacity: 0;
											transition: 0.2s padding ease-in-out;
										}
									}

									.custom-form-send-file {
										align-self: end;
										height: 2.3rem;
										width: 2.3rem;
										flex: 0 0 2.3rem;
										cursor: pointer;
										display: flex;
										justify-content: center;
										align-items: center;
										margin: 0 .25rem;
										position: relative;

										& #file {
											display: inline-block;
											padding: 0 !important;
											position: absolute;
											z-index: 1;
											width: 100%;
											height: 100%;
											top: 0;
											left: 0;
											opacity: 0;
											cursor: pointer;
										}
									}

									.custom-form-send-submit {
										height: 2.3rem;
										width: 2.3rem;
										flex: 0 0 2.3rem;
										align-self: end;
										display: flex;
										justify-content: center;
										align-items: center;
										border-radius: 50%;
										outline: none;
										text-align: center;
										font-size: 1.2rem;
										color: white;
										padding-right: 0.2rem;
										padding-left: 0.1rem;
										margin: 0 .25rem;

										&:focus {
										}

										&:hover {
										}

									}
								}
							}
						}
					}

				}

			}
		}
	}

	#contacts {
		.privacy {
			font-size: 12px;
			line-height: 2em;
			height: 100px;
			overflow-y: scroll;
			border: 1px $card-border-color solid;
			padding: 10px;
			margin: 10px 0 20px 0;
		}
	}

    &.view-ticket {

		@include media-breakpoint-up(xl) {
			&.user-guest #main-content > div {
				max-width: 60%;
				flex-basis: 60%;
				margin: auto;
			}
		}

		#content, #content .container {
			background-color: $gray-100;
		}

		&:not(.layout-invoice) {


			.card {
				margin-bottom: 32px;
				width: 80%;
				border-radius: 20px;
				border-top-left-radius: 0px;
				box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.1);

				&.support-message {
					margin-left: auto;
					border-top-right-radius: 0px;
					border-top-left-radius: 20px;
				}

				.title {
					font-size: 12px;
					margin-top: 16px;
					color: $gray-500;
					display: flex;
					justify-content: space-between;

					.sender {
						text-transform: uppercase;
						letter-spacing: 0.1em;
						margin-right: 2em;
					}

					.date {
						font-style: italic;
					}
				}


				.attachment {
					display: inline-block;
					margin-top: 16px;
					padding-top: 8px;
					border-top: 1px $gray-200 solid;
				}

				.continueBox {
					margin-top: 24px;
					background-color: lighten($primary, 10%);
					color: white;
					padding: 16px;
					font-size: 14px;

					.btn {
						padding-left: 2em;
						padding-right: 2em;

						& + .btn {
							margin-left: 1em;
						}
					}
				}
			}

		}

		&.layout-invoice {
			#previewModal {
				.modal-dialog {
					&.modal-fullscreen {
						.modal-content {
							height: calc(100% - 3.5rem);
						}
					}
				}
			}
		}
	}

	&.view-tickets {

		#content, #content .container {
			background-color: $gray-100;
		}

		.tickets {
			.ticket {
				background-color: #fff;
				padding: 25px;
				margin-bottom: 40px;
				border-radius: 8px;
				box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
			}
		}

	}

}

/** This is the css of the input **/
#w-input-container {
	width: 500px;
	min-height: 20px;
	box-sizing: border-box;
	padding: 9px 18px 11px;
	margin: 5px 10px;
	background-color: red;
	border: 1px solid #fff;
	border-radius: 21px;
}

.w-input-text-group {
	position: relative;
}

.w-placeholder {
	color: #a0a0a0;
	top: 0;
	pointer-events: none;
	user-select: none;
	position: absolute;
	opacity: 0;
	transition: 0.2s padding ease-in-out;
}

#w-input-text {
	overflow-x: hidden;
	overflow-y: auto;
	white-space: pre-wrap;
	word-wrap: break-word;
	z-index: 1;
	max-height: 100px;
	min-height: 20px;
	padding: 0 0 0 2px;
	outline: 0;
	transition: 0.2s padding ease-in-out;
}

#w-input-text:empty + .w-placeholder {
	opacity: 1;
}

#w-input-text:focus + .w-placeholder {
	padding: 0 6px 0px;
}

#w-input-text:focus {
	padding: 0 6px 0px;
}
