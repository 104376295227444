
#wrapper-login{

	height:100vh;
	width:100%;
	position: relative;
	overflow: hidden;

	.wrapper-login-content{
		width:50%;
		height:100%;
		position:absolute;
		z-index:10;
	}

	.wrapper-login-background{
		content:'';
		display: block;
		width:100%;
		height:100%;
		position:absolute;
		z-index:1;
		top:0px;
	}



	@media only screen and (max-width: 992px){
		height: auto !important;

		.wrapper-login-background{
			position: relative;
			width:100% !important;
		}
		.wrapper-login-content{
			position: relative;
			width:100% !important;
			height:auto;
		}
	}


		&.center{
			.wrapper-login-content{left:50%; transform: translateX(-50%);}
		}
		&.left{
			.wrapper-login-content{left:0%;}
		}
		&.right{
			.wrapper-login-content{right:0%;}
		}


			#login{
				height:100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}


}