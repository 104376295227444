.module_mod_iwp_support {
  position: fixed;
  bottom: 25px;
  right: 5px;
  left: 5px;
  z-index: 100;
  pointer-events: none;

  .support-content {
    border-radius: 20px;
    pointer-events: auto;

    .header {
      min-width: 270px;
      width: 0%;
      overflow: hidden;
      float: right;
      cursor: pointer;
      background-color: $mainColor;
      color: #fff;
      padding: 20px;
      border-radius: 30px;
      line-height: 1;
      -webkit-transition: width .3s, border-radius .2s;
      -moz-transition: width .3s, border-radius .2s;
      -ms-transition: width .3s, border-radius .2s;
      -o-transition: width .3s, border-radius .2s;
      transition: width .3s, border-radius .2s;
      transition-delay: .3s;

      .title {
        text-transform: uppercase;
        margin: 0 0 0 10px;
      }

      .close {
        display: none;
        color: #fff;
        font-size: 110%;
        opacity: 0;
        -webkit-transition: opacity .2s;
        -moz-transition: opacity .2s;
        -ms-transition: opacity .2s;
        -o-transition: opacity .2s;
        transition: opacity .2s;
        transition-delay: .3s;
      }

      @include media-breakpoint-down(sm) {
        position: relative;
        min-width: 50px;
        padding: 16px;
        font-size: 18px;

        .title {
          display: none;
        }

        .close {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
    }

    .body {
      float: left;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height .4s;
      -moz-transition: max-height .4s;
      -ms-transition: max-height .4s;
      -o-transition: max-height .4s;
      transition: max-height .4s;
      transition-delay: 0s;

      > div {

        padding: 40px 40px 80px;
        font-size: 14px;
        border: 3px solid #f7f7f7;
        background-color: #fff;
        border-top: 0;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        a:not(.btn) {
          color: $textColor;
        }

        .text-bordered {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $gray-600;
          font-size: 14px;
          font-style: italic;
          margin: 40px 0;

          &:before, &:after {
            background: $gray-600;
            height: 1px;
            flex: 1;
            content: '';
          }

          &:before {
            margin-right: 40px;
          }

          &:after {
            margin-left: 40px;
          }

          @include media-breakpoint-down(md) {
            margin: 20px 0;
          }
        }

        .link {
          text-transform: initial;
        }

        @include media-breakpoint-down(md) {
          padding: 20px;
        }
      }
    }

    &.open {
      .header {
        width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        transition-delay: 0s;
        cursor: auto;

        .close{
            display: block;
            opacity: 1;
          }
      }
      
      .body {
        max-height: 600px;
        transition-delay: .3s;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 500px;
    right: 40px;
    left: auto;
  }
}