$menuColor: $gray-600;
$iconsMenuColor: $gray-600;


#menu {
	background-color: $gray-100;
    //height: $navbar-height;

    nav {
	&.navbar {
      z-index:100;

      .navbar-toggler {
        order: 100;
        box-shadow: none;
        border: none;
        color: $body-color;
      }

      .navbar-brand {
        height: auto;

        > img {
          max-height: 30px;

          @media (max-width: $screen-sm) {
            max-height: 26px;
          }
        }
      }

      .navbar-nav {
        /* MENU DI PRIMO LIVELLO */
        > .nav-item {
          > .nav-link {
            text-transform: uppercase;
            position: relative;
            font-weight: 600;
            font-size: 85%;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
              &:after {
                background: none repeat scroll 0 0 transparent;
                background-color: currentColor;
                border: none;
                margin-left: 0;
                bottom: -1px;
                content: "";
                display: block;
                height: 2px;
                left: 50%;
                opacity: 0;
                position: absolute;
                -webkit-transition-duration: .25s;
                -o-transition-duration: .25s;
                transition-duration: .25s;
                -webkit-transition-property: all;
                -o-transition-property: all;
                transition-property: all;
                -webkit-transition-timing-function: ease-in-out;
                -o-transition-timing-function: ease-in-out;
                transition-timing-function: ease-in-out;
                width: 0;
              }


              &:hover {
                color: currentColor;

                &:after {
                  left: $navbar-nav-link-padding-x;
                  opacity: 1;
                  width: calc(100% - #{$navbar-nav-link-padding-x * 2});
                }
              }
            }
          }

          &.dropdown {

            .dropdown-menu {
              box-shadow: $box-shadow-sm;

              @include media-breakpoint-down(lg) {
                box-shadow: none;
                border: none;
                border-top: 1px solid $gray-200;
                border-radius: 0;
                font-size: .85rem;
              }
            }
          }
        }

        // Megamenu && dropdown
        @include media-breakpoint-up(lg) {
          .dropdown {
            &:not(.bootstrap-select) {
              &.has-megamenu {
                position: static !important;

                &:hover {
                  .megamenu {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility .3s linear, opacity .3s linear;
                  }
                }
              }

              > .dropdown-menu {
                display: block;
                opacity: 0;
                margin-top: var(--#{$prefix}dropdown-spacer);
                visibility: hidden;
                transition: visibility .3s linear, opacity .3s linear;

                > .nav-item {
                  border-bottom: $border-width $border-style $border-color;

                  &.dropdown {
                    > .dropdown-item {
                      &:after {
                        content: "\f054";
                        display: inline-block;
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 900;
                        position: absolute;
                        right: $dropdown-item-padding-x;
                      }
                    }
                  }
                }

                .dropdown {
                  &:not(.bootstrap-select) {
                    .dropdown-menu {
                      position: absolute;
                      left: 100%;
                      top: -2px;
                    }
                  }
                }
              }

              &:hover {
                > .dropdown-menu {
                  visibility: visible;
                  opacity: 1;
                  transition: visibility .3s linear, opacity .3s linear;
                }
              }
            }

            .megamenu {
              left: 0;
              right: 0;
              width: 100%;
              margin-top: $dropdown-spacer !important;
              border-radius: 0;
              overflow-y: scroll;
              display: block;
              border: none;
              visibility: hidden;
              opacity: 0;
              transition: visibility .3s linear, opacity .3s linear, transform .2s ease-in-out .6s;
              box-shadow: $box-shadow-sm;
              top: 100%;
              padding: 0;

              &.show {
                visibility: visible;
                opacity: 1;
                transition: visibility .3s linear, opacity .3s linear;

              }
            }
          }
        }
      }
	}

    .navbar-offcanvas.in {
		padding-left: 20px;
		padding-right: 20px;
		margin-left: 0;

		.dropdown-menu {
			background: none;
		}
	}

    @include media-breakpoint-between(md, lg) {
		.navbar .container {
			flex-wrap: wrap;
		}

		.nav-links {
			order: 3;
			flex-basis: 100%;
		}
	}



	@include media-breakpoint-down(md) {
		.offcanvas-menu {
			position: fixed;
			height: 100%;
			z-index: 101;
			top: 0;
			left: 0;
			min-width: 200px;
			margin: 0;
			width: 70vw;
			max-width: 350px;
            overflow: auto;
			transform: translateX(-100%);
            transition: transform .2s cubic-bezier(0.4, 0.0, 0.2, 1);

            &.in {
                transform: translateX(0);
				@include box-shadow(3);
            }

            .navbar-nav {
                margin: 0;

                .nav-item {
                    border-radius: 0;
					border: none;

                    > a, .dropdown-toggle {
                        padding: 16px 24px;
                    }

					.dropdown-toggle {
						right: 0;
						color: white;
					}

					.dropdown-menu {
						background-color: transparent;
						box-shadow: none;
						border: none;
						padding-left: 16px;

						.nav-link {
							padding: 16px 24px;
						}
					}
                }
            }
		}
	}
  }
}

@include media-breakpoint-down(md) {

	#menu {
		position: relative;
		//padding-top: 32px;


      .navbar {
			border: none;
			background-color: lighten($mainColor, 10%);
			@include box-shadow(1);
			position: relative;
			width: 100%;
		}

		.container {
			max-width: none;
			background-color: transparent;
		}

		.nav-links {
			background-color: lighten($mainColor, 10%);
		}

    }

}