#content, #main-content {
	padding-top: 24px;
	padding-bottom: 24px;
}


#footer {
	background-color: $secondary;
	padding: 40px 0px;

	.module {
		color: white;

		h5 {
			margin-bottom: 24px;
		}
	}

    .navbar-nav {
      .nav-link {
        color: $primary;
      }
    }
}

.page-header {
	text-align: center;
	margin-bottom: 40px;

	> h1 {
		@extend .h3;
	}
}

.grecaptcha-badge {
	z-index: 10;
}

.error-page-message {
	text-align: center;

	.code {
		color: $gray-200;
		font-size: 20vw;
		text-align: center;
		line-height: 1;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			font-size: 30vw;
		}
	}

	.message {
		position: relative;
		top: -50%;
		font-size: 48px;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
	}
}
