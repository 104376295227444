#range-box {


  .range-slider {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;


    .price-range {

      &.noUi-horizontal {
        height: 4px;
        border-radius: 4px;
        border: none;
        box-shadow: none;
        background-color: #eee;
      }

      .limits {
        margin-bottom: 10px;
      }

      .price-values {
        margin-top: 10px;
      }

      .noUi-connect {
        background-color: lighten($primary, 20%);
        box-shadow: none;
      }

      .noUi-handle {
        height: 20px;
        width: 20px;
        top: -8px;
        right: -10px; /* half the width */
        border-radius: 50%;
        border: none;
        background-color: $primary;
        box-shadow: 0 0 0 0 rgba($primary, .1);
        transition: box-shadow .3s ease-in-out;

        &:before, &:after {
          display: none;
        }

        &:hover {
          box-shadow: 0 0 0 15px rgba($primary, .1);
        }

        &.noUi-active {
          box-shadow: 0 0 0 18px rgba($primary, .2);
        }
      }

      .noUi-tooltip {
        border: none;
        font-size: 80%;
        background: transparent;
      }

      .noUi-value {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
}