.module_mod_iwp_categories {

    .categories{
      .owl-carousel {
            .item{
                padding: 0px 10px;
                @include media-breakpoint-up(md) {
                    padding: 0px 15px;
                }
            }
            .category{margin:0px;}
        }
    }

    .category {

      position: relative;
      transition: all 0.2s ease-out;
      border-radius: 8px;

      &:hover{
          box-shadow: 8px 8px 24px rgba(0,0,0,0.25);
          transform: translateY(-15px);
          figure:after{
              background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
          }
      }


      figure{
          margin:0px;
          border-radius: 8px;
          overflow: hidden;

          &:after {
              content: '';
              background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
              display: block;
              height: calc(100% + 1px);
              width: calc(100% + 1px);
              top: 0;
              left: 0;
              position: absolute;
              border-radius: 8px;
          }

          img {
            max-width: none;
            width: 100%;
            transition: all .2s ease-in-out;
          }
      }


      figcaption {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          @include media-breakpoint-down(sm) {
              h2{
                  font-size: 1rem;
              }
          }


          *{
              color: white;
          }
      }

        a, a:visited, a:hover{
            color: white;
            text-decoration: none;
        }


    }

}